import React from "react";
import { TFunction } from "i18next";
import { NomineeCompanyDetails_companyById_adminService } from "../../../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { UpdateCompanyCreationVariables } from "../../../../../lib/api/mutations/graphql/UpdateCompanyCreation";
import { useFormik } from "formik";
import EditableSectionWithFormik from "../../../../../sharedComponents/EditableSectionWithFormik/EditableSectionWithFormik";
import RideDatePicker from "../../../../../sharedComponents/RideDatePicker/RideDatePicker";
import { formatDate } from "../../../../../common/formatters";
import { ValueView } from "../../../../common/layout/ValueView";

export const UpdateAdminServiceSection = ({
  t,
  adminService,
  updateAdminService,
  refetchCompany
}: {
  t: TFunction;
  adminService: NomineeCompanyDetails_companyById_adminService;
  updateAdminService: (data: UpdateCompanyCreationVariables) => Promise<void>;
  refetchCompany: () => Promise<any>;
}) => {
  const handleSubmit = async (data: UpdateCompanyCreationVariables) => {
    await updateAdminService(data);
    await refetchCompany();
  };

  const initialValues: UpdateCompanyCreationVariables = {
    id: adminService.id,
    canceledAt: adminService.canceledAt
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  return (
    <EditableSectionWithFormik
      formik={formik}
      title={t("nominee-dashboard:admin-service.details")}
      testId="adminServiceDetails">
      {({ isEditMode }) =>
        isEditMode ? (
          <RideDatePicker
            name={"canceledAt"}
            label={t("nominee-dashboard:admin-service.canceledAt")}
            selectedDate={formik.values["canceledAt"]}
            onSelectDate={(value) => formik.setFieldValue("canceledAt", value)}
          />
        ) : (
          <>
            <ValueView
              name="canceledAt"
              value={formatDate(adminService.canceledAt)}
              testId="canceledAt"
              t={t}
            />
            <ValueView
              name="isArchived"
              value={
                adminService.isArchived !== undefined ? adminService.isArchived?.toString() : "N/A"
              }
              testId="isArchived"
              t={t}
            />
          </>
        )
      }
    </EditableSectionWithFormik>
  );
};
