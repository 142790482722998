import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetailsVariables
} from "./graphql/NomineeCompanyDetails";

export const NOMINEE_COMPANY_DETAILS_QUERY = gql`
  query NomineeCompanyDetails($id: String!) {
    companyById(companyId: $id) {
      id
      name
      legalForm
      locked
      registerCourt
      registration
      taxNumber
      economicId
      vatNumber
      advisorNumber
      externalStorageNodes
      notarizationDate
      applicationForEntryDate
      commercialRegisterEntryDate
      lei
      foundationDate
      asUpgradeRequiredFrom
      migrationRequiredFor3PTASince
      migrationRequiredFor3PTARealizedAt
      externalStorageUsers {
        id
        email
        externalId
        status
        person {
          id
          firstName
          lastName
          user {
            id
            email
          }
        }
      }
      type
      assets
      businessPurpose
      businessPurposeShort
      employees
      clientNumber
      tokenized
      tokenizedShares
      tradeTaxFree
      capitalContribution
      status
      boxLink
      projectName
      brokerageSurvey {
        id
        answer
        reason
        otherReason
        selectedBrokerBank
        otherBrokerBank
        updatedAt
      }

      discountCodeUse {
        id
        discountCode {
          id
          code
          referrerValue
        }
        status
      }

      ownedWallet {
        id
        wallet
        walletProviderId
        type
        userWallet {
          id
        }
      }

      businessAddress {
        careOf
        street
        streetLine2
        postalCode
        city
        country
      }
      myTaxService {
        id
        type
        taxAdvisorStatus
        updatedAt
        activatedAt
        advisor {
          id
          name
        }
      }
      myWepaSubContracting {
        id
        taxAdvisorStatus
        updatedAt
        activatedAt
        thirdParty {
          id
          name
        }
      }
      group {
        memberships {
          id
          role
          company {
            id
            name
            legalForm
            deletedAt
            kycEntries {
              id
              status
              company {
                id
              }
            }
          }
          person {
            id
            firstName
            lastName
            memberships {
              group {
                company {
                  id
                  name
                }
              }
            }
            user {
              id
              email
            }
            kycEntries {
              id
              status
              company {
                id
              }
            }
          }
          share {
            share
          }
        }
      }

      kycEntries {
        id
        type
        startedAt
        completedAt
        status
        incompleteReason
      }

      companyCreation {
        id
        currentStep
        flowType
        hasOperationalActivities
        operationalActivities
        creator {
          memberships {
            person {
              id
            }
          }
        }
        notaryService {
          id
          firstParty {
            id
            firstName
            lastName
          }
          thirdParty {
            id
            firstName
            lastName
          }
          firstPartyAppointment
          thirdPartyAppointment
        }
      }

      adminService {
        id
        currentStep
        flowType
        canceledAt
        tier
        sepaReferenceNumber
        isArchived
      }

      wepaService {
        id
        canceledAt
      }

      wepaId
      liability
      fiscalYear

      testCompany

      bankAccounts {
        id
        name
        bankProvider
        iban
        plan
      }

      adminServiceInvoiceData {
        id
        setupFeeType
        setupFeeValue
        contractSignDate
        servicePeriodStartingDate
        servicePeriodEndingDate
        invoicePeriodType
        invoiceableStatus
        additionalNotes
        conditionType
        conditionTypeSpecial
        terminationStatus
        terminationEndingDate
        isClientOf
      }

      wepaServiceInvoiceData {
        id
        setupFeeType
        setupFeeValue
        contractSignDate
        servicePeriodStartingDate
        servicePeriodEndingDate
        invoicePeriodType
        invoiceableStatus
        additionalNotes
        conditionType
        conditionTypeSpecial
        terminationStatus
        terminationEndingDate
        isClientOf
      }

      payrollInvoiceData {
        payrollServiceType
        payrollServiceStart
        payrollServiceEnd
        payrollServicePrice
      }
      orders {
        id
        productType
        status
        extra
        submissionId
        deletedAt
      }
    }
  }
`;

export const GetNomineeCompanyDetailsById = (id: string) =>
  useQuery<NomineeCompanyDetails, NomineeCompanyDetailsVariables>(NOMINEE_COMPANY_DETAILS_QUERY, {
    variables: { id },
    fetchPolicy: "no-cache"
  });
